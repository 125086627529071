

































































@import "./src/assets/css/project-variables";

.home-charts-container{

  .chart-name {
    text-align: center;
    font-weight: bold;
  }
}

